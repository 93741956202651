import React, { useState } from 'react';
import { bool, func, oneOfType, string } from 'prop-types';
import truncate from 'lodash/truncate';
import classNames from 'classnames';

import { FormattedMessage } from '../../../util/reactIntl';
import { ensureUser, ensureCurrentUser } from '../../../util/data';
import { propTypes } from '../../../util/types';

import { AvatarLarge, NamedLink, InlineTextButton } from '../../../components';

import css from './UserCard.module.css';

// Approximated collapsed size so that there are ~three lines of text
// in the desktop layout in the author section of the ListingPage.
const BIO_COLLAPSED_LENGTH = 170;

const truncated = s => {
  return truncate(s, {
    length: BIO_COLLAPSED_LENGTH,

    // Allow truncated text end only in specific characters. This will
    // make the truncated text shorter than the length if the original
    // text has to be shortened and the substring ends in a separator.
    //
    // This ensures that the final text doesn't get cut in the middle
    // of a word.
    separator: /\s|,|\.|:|;/,
    omission: '…',
  });
};

const ExpandableBio = props => {
  const [expand, setExpand] = useState(false);
  const { className, bio } = props;
  const truncatedBio = truncated(bio);

  const handleShowMoreClick = () => {
    setExpand(true);
  };
  const showMore = (
    <InlineTextButton rootClassName={css.showMore} onClick={handleShowMoreClick}>
      <FormattedMessage id="UserCard.showFullBioLink" />
    </InlineTextButton>
  );
  return (
    <p className={className}>
      {expand ? bio : truncatedBio}
      {bio !== truncatedBio && !expand ? showMore : null}
    </p>
  );
};

ExpandableBio.defaultProps = { className: null };

ExpandableBio.propTypes = {
  className: string,
  bio: string.isRequired,
};

const UserCard = props => {
  const { rootClassName, className, user, currentUser, onContactUser, showContact, isIframe, isCreator = false, hasCreator = false, listing = null } = props;

  const userIsCurrentUser = user && user.type === 'currentUser';
  const ensuredUser = userIsCurrentUser ? ensureCurrentUser(user) : ensureUser(user);

  const ensuredCurrentUser = ensureCurrentUser(currentUser);
  const isCurrentUser =
    ensuredUser.id && ensuredCurrentUser.id && ensuredUser.id.uuid === ensuredCurrentUser.id.uuid;
  const { displayName, bio } = ensuredUser.attributes.profile;

  const handleContactUserClick = () => {
    onContactUser(user);
  };

  const hasBio = !!bio;
  const classes = classNames(rootClassName || css.root, className);
  const linkClasses = classNames(css.links, {
    [css.withBioMissingAbove]: !hasBio,
  });

  const contact = (showContact || isCreator) && !isIframe ? (
    <InlineTextButton
      rootClassName={css.contact}
      onClick={handleContactUserClick}
      enforcePagePreloadFor="SignupPage"
    >
      <FormattedMessage id="UserCard.contactUser" />
    </InlineTextButton>
  ) : null;

  const editProfileMobile = (
      <NamedLink name="ProfileSettingsPage" className={css.contact}>
        <FormattedMessage id="ListingPage.editProfileLink" />
      </NamedLink>
  );

  let redirectName = 'ProfilePage';
  let redirectParams = { id: ensuredUser.id.uuid };
  let redirectMessage = 'UserCard.viewProfileLink';
  let authorId;
  if (listing) {
    let { isAssociated = false, } = listing?.attributes?.publicData || {};
    authorId = listing?.author?.id?.uuid;
    if (isAssociated && authorId) {
      redirectName = 'TeamPage';
      redirectParams = { id: authorId, type: 'detail' };
      redirectMessage = 'UserCard.backToStore';
    }
  }

  let userLinks = <p className={linkClasses}>
    {isCurrentUser ? editProfileMobile : contact}
    <NamedLink className={css.profileLink} name={redirectName} params={redirectParams}>
      <FormattedMessage id={redirectMessage} />
    </NamedLink>
  </p>;

  const links = ensuredUser.id ? (!isIframe ?
    (hasCreator && isCreator ? userLinks : (!hasCreator ? userLinks : null))
  : <p className={linkClasses}>
    {isCurrentUser ? null : contact}
    {hasCreator && !isCreator ? null :<NamedLink className={css.profileLink} name="StorePage" params={{ id: authorId || ensuredUser?.id?.uuid }}>
      Back to Store
    </NamedLink>}
  </p>) : null;

  return (
    <div className={classes}>
      <div className={css.content}>
        <AvatarLarge className={css.avatar} user={user} isIframe={isIframe} disableProfileLink/>
          <div className={css.info}>
            <div className={css.headingRow}>
              <div className={css.name}>
                <FormattedMessage id="UserCard.heading" values={{ name: displayName }} />
                &nbsp;{isCreator ? <span>(Creator)</span> : null}
              </div>
            </div>
            {hasBio ? <ExpandableBio className={css.desktopBio} bio={bio} /> : null}
          </div>
          <div className={css.desktopBio}>
            {links}
          </div>
      </div>
      {hasBio ? <ExpandableBio className={css.mobileBio} bio={bio} /> : null}
      <div className={css.mobileBio}>
        {links}
      </div>
    </div>
  );
};

UserCard.defaultProps = {
  rootClassName: null,
  className: null,
  user: null,
  currentUser: null,
  showContact: true,
  isIframe: false,
};

UserCard.propTypes = {
  rootClassName: string,
  className: string,
  // user: oneOfType([propTypes.user, propTypes.currentUser]),
  currentUser: propTypes.currentUser,
  onContactUser: func,
  showContact: bool,
  isIframe: bool,
};

export default UserCard;
